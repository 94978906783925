import {
	createApp
} from 'vue'
import 'default-passive-events'
import 'lib-flexible/flexible'
import './assets/styles/index.scss'
import App from './App.vue'
import router from './router'
import './permission'
import store from './store'
import { Popup } from 'vant'
import {
	fnHandleSearch
} from './utils/public.js'
// import vConsole from '@/utils/vconsole.min.js'
// new vConsole();
import {
	Tabbar,
	TabbarItem,
	Sticky,
	NavBar,
	Form,
	CellGroup,
	Field,
	Button,
	Toast,
	Checkbox,
	CheckboxGroup,
	Cell,
	Dialog,
	Swipe,
	SwipeItem,
	Skeleton,
	Lazyload,
	Image as VanImage,
	Collapse,
	CollapseItem,
	Tab, Tabs,
	Empty,
	Icon,
	List,
	ActionSheet,
	Stepper,
	DropdownMenu,
	DropdownItem,
	RadioGroup,
	Radio,
	Switch,
	Overlay,
	Search,
	Uploader
} from 'vant'
import loading from '@/assets/images/img_015.png'
import swipeBg from '@/assets/images/img_016.png'

// Toast.loading();

const appGlobal = createApp(App);
appGlobal.use(Tabbar).use(TabbarItem).use(Sticky).use(NavBar).use(Form).use(CellGroup).use(Field).use(Button).use(Checkbox).use(CheckboxGroup).use(
	Toast).use(Cell).use(Dialog).use(Swipe).use(SwipeItem).use(Skeleton).use(Popup).use(Lazyload, {
	lazyComponent: true,
	attempt: 3,
	loading: swipeBg,
	error: swipeBg,
}).use(VanImage).use(Collapse).use(CollapseItem).use(Tab).use(Tabs).use(Empty).use(Icon).use(List).use(ActionSheet).use(Stepper).use(DropdownMenu).use(DropdownItem).use(RadioGroup).use(Radio).use(Switch).use(Overlay).use(Search).use(Uploader).use(store).use(router).mount('#app');



// 获取到window.location.search的值
// const locationSearch = window.location.search;
const locationSearch = unescape(window.location.search);
const str = fnHandleSearch(locationSearch, "subAppId");
store.dispatch('userInfo/USubAppid', str)

// console.log(appGlobal.config.globalProperties)
// const on = appGlobal.config.globalProperties.$on;
// appGlobal.config.globalProperties.$on = function (event, func) {
// 	console.log("执行防抖");
// 	console.log(event)
//   let timer;
//   let flag = true;
//   let newFunc = func
//   if (event == 'click') {
//     newFunc = function () {
//       if(flag) {
//         func.apply(this, arguments)
//         flag = false
//       }
//       clearTimeout(timer)
//       timer = setTimeout(function () {
//         flag = true
//       }, 3000)
//     }
//   }
//   on.call(this, event, newFunc)
// }

/* 必要，为了解决路由缓存时组件未加载，但是线上环境更新再去访问会导致组件找不到 */
router.onError(err => {
	console.log("--捕获到路由错误如下显示--");
	console.log(err);
	console.log(err.message);
	// console.log(/^Loading chunk (\d)+ failed\./);
	const pattern =  /^Couldn't resolve component/;
	const isChunkLoadingFailed = pattern.test(err.message);
	if (isChunkLoadingFailed) {
		Toast({
			message: '服务器版本已更新，正在刷新本地缓存，请稍后...',
			position: 'bottom'
		});
		location.reload(true);
	}
})

/* 全局修改默认加载样式 */
Toast.setDefaultOptions('loading', {
	icon: loading,
	duration: 0,
	forbidClick: true
});
