import Compressor from 'compressorjs';




/**
 *获取ua判断是否是内嵌app
 */
// export function uaFun() {
// 	var userAgent = navigator.userAgent.toLowerCase();//获取UA信息
// 	var apphight = document.documentElement.clientHeight||window.innerHeight
// 	return userAgent.indexOf("safari") == -1 && apphight >= 812 ? true : false
//
// }
export function uaFun() {
	var userAgent = navigator.userAgent.toLowerCase();//获取UA信息
	var apphight = document.documentElement.clientHeight||window.innerHeight
	return userAgent.indexOf("mjfc") != -1 || userAgent.indexOf("fcone") != -1 && apphight >= 812 ? true : false
}

/**
 * [fnFormatData 标准日期/时间戳 格式化]
 * @param  {[Date,number]} date [需要格式的标准日期/时间戳]
 * @param  {[String]} fmt  [例子：yyyy-MM-dd hh:mm:ss 连接上做处理]
 * @return {[String]}      [返回格式化后的的时间]
 * like 1610092364000 => 2021-01-08 15:52:44
 */
export function fnFormatData(date, fmt = 'yyyy-MM-dd hh:mm:ss') {
	if (!date) return '';
	let aDate = new Date(+date);
	let o = {
		"M+": aDate.getMonth() + 1, //月份
		"d+": aDate.getDate(), //日
		"h+": aDate.getHours(), //小时
		"m+": aDate.getMinutes(), //分
		"s+": aDate.getSeconds(), //秒
		"q+": Math.floor((aDate.getMonth() + 3) / 3), //季度
		"S": aDate.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (aDate.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (let k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
		}
	}
	// console.log(fmt)
	return fmt;
}

/**
 * [fnHandleStringCenter 处理字符串除了两端中间的值]
 * @param  {[String]} Str [需要处理的字符串]
 * @param  {[String]} Name  [需要获取到的key]
 * @return {[String]}      [返回处理后得到的数据]
 * like https://h5.dev.utroninfo.com?supappid=2&name=3/#/ => 2
 */
export function fnHandleSearch(Str, Name) {
	let tStr = '';
	// const tStr = Str.slice(1,-1);// 取中间 supappid=2&name=3
	if(Str.substr(Str.length-1,1) == '/'){
		tStr = Str.slice(1,-1)
	}else {
		tStr = Str.slice(1)
	}
	const tArr = tStr.split("&");// 处理如果是https://h5.dev.utroninfo.com?supappid=2&name=3/#/只取supappid的情况
	let tSave = null;
	tArr.forEach(item => {
		let tArrA = item.split("=");
		if (tArrA[0] === Name) {
			tSave = tArrA[1]
		}
	})

	return tSave
}

/**
 * [fnFormatColor 字符串截取替换]
 * @param  {[String]} date [需要格式的字符串]
 * @return {[String]}      [返回格式化后的字符串]
 * like 1610092364000 => 2021-01-08 15:52:44
 */
export function fnFormatColor(Str) {
	let value = Str
	if (Str) {
		value = Str.replace(/\/color(.*?)\/color/g, "<span class='cE2335D'>$1</span>");
	}
	return value;
}

/**
 * [fnPlanStatusCode 方案状态code值]
 * @param  {[String]} code [需要筛选的字段]
 * @return {[String]}      [返回转化后的字段]
 */
export function fnPlanStatusCode(code) {
	// console.log(code)
	code += "";
	let tData = "";
	switch (code) {
		case "1":
			tData = "去付款"
			break;
		case "5":
			tData = "提交出票"
			break;
		case "10":
			tData = "受理出票"
			break;
		case "13":
			tData = "出票成功待审核"
			break;
		case "15":
			tData = "待开奖"
			break;
		case "20":
			tData = "已开奖"
			break;
		case "25":
			tData = "彩票兑奖中"
			break;
		case "30":
			tData = "已兑奖"
			break;
		case "35":
			tData = "退单（已退款)"
			break;
		case "40":
			tData = "退单"
			break;
		case "45":
			tData = "已取消"
			break;
		default:
			tData = code
			break;
	}
	return tData;
}
/**
 * [fnPlanDetailsStatusCode 方案详情状态code值]
 * @param  {[String]} code [需要筛选的字段]
 * @return {[String]}      [返回转化后的字段]
 */
export function fnPlanDetailsStatusCode(code) {
	code += "";
	let tData = "";
	switch (code) {
		case "1":
			tData = "待付款"
			break;
		case "5":
		case "10":
			tData = "受理出票"
			break;
		case "13":
			tData = "出票成功待审核"
			break;
		case "15":
			tData = "待开奖"
			break;
		case "20":
			tData = "已开奖"
			break;
		case "25":
			tData = "已中奖（兑奖中)"
			break;
		case "30":
			tData = "已兑奖"
			break;
		case "35":
			tData = "退单（已退款)"
			break;
		case "40":
			tData = "退单（已退款)"
			break;
		case "45":
			tData = "已取消"
			break;
		default:
			tData = ""
			break;
	}
	return tData;
}
/**
 * [fnPlanSuccess 方案交易成功,且未退款]
 * @param  {[String]} code [需要筛选的字段]
 * @return {[Boolean]}      [返回转化后的字段]
 */
export function fnPlanSuccess(code) {
	code += "";
	let tData = false;
	switch (code) {
		case "15":
		case "20":
		case "25":
		case "30":
			tData = true;
			break;
		default:
			tData = false;
			break;
	}
	return tData;
}
/**
 * [fnPlanStatusCodeColor 方案状态code值对应颜色]
 * @param  {[String]} code [需要筛选的字段]
 * @return {[String]} fontColor [返回对应颜色]
 */
export function fnPlanStatusCodeColor(code) {
	code += "";
	let fontColor = "";
	switch (code) {
		case "5":
		case "10":
			fontColor = 'cFEAA30';
			break;
		case "13":
		case "15":
		case "20":
		case "25":
			fontColor = 'cFF7671';
			break;
		case "35":
		case "40":
		case "45":
			fontColor = "c383838";
			break;
		default:
			fontColor = "c383838"
			break;
	}
	return fontColor;
}

/**
 * [fnRecordGDStatusCode 跟单记录]
 * @param  {[String]} code [需要筛选的字段]
 * @return {[String]}      [返回转化后的字段]
 */
export function fnRecordGDStatusCode(code) {
	code += "";
	let tData = "";
	switch (code) {
		case "1":
			tData = "等待付款"
			break;
		case "5":
			tData = "提交出票"
			break;
		case "10":
			tData = "受理出票"
			break;
		case "13":
			tData = "出票成功待审核"
			break;
		case "15":
			tData = "待开奖"
			break;
		case "20":
			tData = "已开奖"
			break;
		case "25":
			tData = "已中奖（兑奖中)"
			break;
		case "30":
			tData = "已兑奖"
			break;
		case "35":
			tData = "退单（已退款)"
			break;
		case "40":
			tData = "退单（已退款)"
			break;
		case "45":
			tData = "已取消"
			break;
		default:
			tData = ""
			break;
	}
	return tData;
}

// 过关类型
export function fnMixTypes(val) {
	val += "";
	let str = "";
	str = val.split("^").join("/");
	return str;
}

// 描述过滤
export function fnDescription(type) {
	type += 0;
	let str = "";
	switch (type) {
		case 400:
			str = '加载失败，请点击刷新重试'
			break;
		case 4041:
			str = '未找到相应数据哦~'
			break;
		case 4042:
			str = '没有网络哦~'
			break;
		case 4043:
			str = '请求超时，请稍后再试'
			break;
		default:
			str = '暂无数据~'
			break;
	}
	return str;
}

// van-empty 组件是否展示
export function fnIsShowEmpty(type) {
	type += 0;
	let str = false;
	switch (type) {
		case 400:
		case 4041:
			str = 'nodata';
			break;
		case 4042:
		case 4043:
			str = "noNetWork";
			break;
		default:
			str = false
			break;
	}
	return str;
}

/**
 * fnKeyIsTrue 有效的key 过滤所有的key对应的value是否为true
 * @param {Object} obj
 * @returns {Boolean}
 */
export function fnKeyIsTrue(obj) {
	let tObj = JSON.parse(JSON.stringify(obj));
	// console.log(tObj);
	// 创建临时数组
	let tArr = Object.keys(tObj);
	// console.log(tArr)
	// 是否全部为true
	let bIsAllTrue = true;
	for (let i = 0, j = tArr.length; i < j; i++) {
		// console.log(tArr[i]+":"+tObj[tArr[i]])
		// 如果该对象其中一个value为false，则返回false
		if (tObj[tArr[i]] === null || tObj[tArr[i]] === undefined || tObj[tArr[i]] === '' || tObj[tArr[i]] === false) {
			bIsAllTrue = false;
			break;
		}
	}
	return bIsAllTrue
}

/**
 * @param image 图片
 * @param quality 图片压缩比 0-1,数字越小，图片压缩越小
 * @param backType 需要返回的类型blob,file
 * @returns
 */
export function fnCompressorImage(image, quality, backType) {
	return new Promise((resolve, reject) => {
		new Compressor(image, {
			quality: quality || 0.8,
			success(result) {
				let file = new File([result], image.name, {
					type: image.type
				})

				if (!backType || backType == 'blob') {
					resolve(result)
				} else if (backType == 'file') {
					resolve(file)
				} else {
					resolve(file)
				}
			},
			error(err) {
				console.log('图片压缩失败---->>>>>', err)
				reject(err)
			}
		})
	})
}

/**
 * [fnSecond 时间戳转换为秒]
 * @param  {[Number]} Time [输入时间戳]
 * @return {[Number]}     [秒为单位的时间戳]
 */
export function fnSecond(Time) {
	return Math.floor(Time / 1000);
}

/**
 * [fnDeepClone 深拷贝]
 * @param  {[Any]} Data [输入原数据]
 * @return {[Any]}     [输出深拷贝后数据]
 */
export function fnDeepClone(Data) {
	return JSON.parse(JSON.stringify(Data))
}

/**
 * [fnIsToday 时间戳转换为秒]
 * @param  {[String]} Time [输入时间]
 * @return {[Boolean]}     [秒为单位的时间戳]
 */
export function fnIsToday(Time) {
	return new Date().toDateString() === new Date(Time).toDateString();
}

/**
 * [fnKeepFigures 保留小数点]
 * @param  {[String]} money [需要筛选的字段]
 * @param  {[Number]} digit [小数点保留的位数]
 * @return {[String]}  RMB  [返回转化后的字段]
 */
export function fnKeepFigures(money, digit = 2) {
	let RMB = 0.00;
	if (isNaN(money)) {
		RMB = 0.00;
	} else {
		RMB = (Number(money)).toFixed(digit);
	}
	return RMB
}

/**
 * [fnObjForArraySort 数组中的对象按照某个属性排序]
 * [sort 方法会直接修改原数组]
 * @param  {[Array]} Arr [需要排序的数组]
 * @param  {[String]} Key [属性名称]
 * @return {[Null]}
 */
export function fnObjForArraySort(Arr, Key) {
	Arr.sort((value1, value2) => {
		const val1 = value1[Key];
		const val2 = value2[Key];
		return val1 - val2;
	});
}

/**
 * [fnKeepDecimals 保留小数点后n位]
 * @param  {[String]}		Str		[输入对象]
 * @param  {[Number]}		n			[小数点后n位]
 * @return {[String]}		Str		[字符串]
 */
export function fnKeepDecimals(Str, n) {
	let tStr = Str + "";
	tStr = tStr.replace(/^[0][0-9]/g, "") //清楚以0开始的非小数的字符
	tStr = tStr.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
	tStr = tStr.replace(/^\./g, ""); //验证第一个字符是数字而不是
	tStr = tStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
	tStr = tStr.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
	if (n === 1) {
		tStr = tStr.replace(/^(\d+)\.(\d).*$/, '$1.$2'); //保留一个位小数
	} else if (n === 2) {
		tStr = tStr.replace(/^(\d+)\.(\d\d).*$/, '$1.$2'); //保留两位小数
	} else if (n === 3) {
		tStr = tStr.replace(/^(\d+)\.(\d\d\d).*$/, '$1.$2'); //保留三位小数
	}
	return tStr;
}

/**
 * [fnClearNoInteger 整数]
 * @param  {[String]} str [输入对象]
 * @return {[str]}     [字符串]
 */
export function fnClearNoInteger(str) {
	str += '';
	str = str.replace(/\D/g, '');
	return str;
}


/**
 * fnCopyInnerText 一键粘贴
 * @param  {String} value [需要粘贴的内容]
 */
export function fnCopyInnerText(value) {
	const input = document.createElement('input');
	input.setAttribute('readonly', 'readonly');
	input.setAttribute('value', value);
	document.body.appendChild(input);
	input.select();
	input.setSelectionRange(0, input.value.length);
	if (document.execCommand("copy")) {
		document.execCommand("copy");
		document.body.removeChild(input);
		return true;
	} else {
		document.body.removeChild(input);
		return false;
	}
}


/**
 * fnGetEnvironment 判断浏览器是否为微信或则其它APP浏览器
 *
 */
export function fnGetEnvironment() {
	var UA = navigator.userAgent.toLowerCase();
	let environment = "Unknown"; // 未知
	switch (true) {
		case UA.indexOf('micromessenger') !== -1:
			environment = "WeiXin";
			break;
		case UA.indexOf('weibo') !== -1:
			environment = "WeiBo";
			break;
		case UA.indexOf('qq') !== -1:
			environment = "QQ";
			break;
		case UA.indexOf('android') !== -1:
			environment = "Android";
			break;
		case /iphone|ipad|ipod|ios/.test(UA):
			environment = "IOS";
			break;
		default:
			environment = "Unknown";
			break;
	}

	return environment;
}


/**
 * fnJumpApp 跳转APP
 *
 */
export function fnJumpApp(PlanId) {
	const Environment = fnGetEnvironment();
	if (Environment === "Android") {
		if (PlanId && typeof PlanId != "object") {
			window.location.href = 'scheme://fczj:8989/main?planId=' + PlanId;
		} else {
			window.location.href = 'scheme://fczj:8989/main';
		}
	} else if (Environment === "IOS") {
		if (PlanId && typeof PlanId != "object") {
			window.location.href = 'lotterydata://follow?planId=' + PlanId;
		} else {
			window.location.href = 'lotterydata://';
		}
	} else {
		alert('请在浏览器上打开');
	}
	if (Environment === "Android" || Environment === "IOS") {
		setTimeout(() => {
			let hidden = window.document.hidden || window.document.mozHidden || window
				.document.msHidden || window.document.webkitHidden;
			if (typeof hidden == "undefined" || hidden == false) {
				// 跳转下载页面
				// window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient";
				window.location.href = "https://www.xingyuninfo.com/flow/html/download.html";
			}
		}, 200);
	}
}

/**
 * 将科学计数法的数字转为字符串
 * 说明：运算精度丢失方法中处理数字的时候，如果出现科学计数法，就会导致结果出错
 * 4.496794759834739e-9  ==> 0.000000004496794759834739
 * 4.496794759834739e+9  ==> 4496794759.834739
 * @param  num
 */
function toNonExponential(num) {
	if (num == null) {
		return num;
	}
	if (typeof num == "number") {
		var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
		return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
	} else {
		return num;
	}
}


/**
 * fnFloatAdd 加法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 + 0.00000000000001 ==> 0.0023000000000099998
 * {{ 0.0023 | plus(0.00000000000001) }} ==> 0.00230000000001
 */
export function fnFloatAdd(arg1, arg2) {
	arg1 = Number(arg1) || 0;
	arg2 = Number(arg2) || 0;
	arg1 = toNonExponential(arg1);
	arg2 = toNonExponential(arg2);
	var r1, r2, m;
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	return (fnFloatMultiply(arg1, m) + fnFloatMultiply(arg2, m)) / m;
}


/**
 * fnFloatSub 减法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 - 0.00000011  ==>  0.0022998899999999997
 * {{ 0.0023 | minus( 0.00000011 ) }}  ==>  0.00229989
 */
export function fnFloatSub(arg1, arg2) {
	arg1 = Number(arg1) || 0;
	arg2 = Number(arg2) || 0;
	arg1 = toNonExponential(arg1);
	arg2 = toNonExponential(arg2);
	var r1, r2, m, n;
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	// 动态控制精度长度
	n = (r1 >= r2) ? r1 : r2;
	return ((fnFloatMultiply(arg1, m) - fnFloatMultiply(arg2, m)) / m).toFixed(n);
}

/**
 * fnFloatMultiply 乘法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 * 100 ==> 0.22999999999999998
 * {{ 0.0023 | multiply(100) }} ==> 0.23
 */
export function fnFloatMultiply(arg1, arg2) {
	arg1 = Number(arg1);
	arg2 = Number(arg2);
	if ((!arg1 && arg1 !== 0) || (!arg2 && arg2 !== 0)) {
		return null;
	}
	arg1 = toNonExponential(arg1);
	arg2 = toNonExponential(arg2);
	var n1, n2;
	var r1, r2; // 小数位数
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	n1 = Number(arg1.toString().replace(".", ""));
	n2 = Number(arg2.toString().replace(".", ""));
	return n1 * n2 / Math.pow(10, r1 + r2);
}

/**
 * fnFloatDivide 除法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 / 0.00001 ==> 229.99999999999997
 * {{ 0.0023 | divide(0.00001) }} ==> 230
 */
export function fnFloatDivide(arg1, arg2) {
	arg1 = Number(arg1);
	arg2 = Number(arg2);
	if (!arg2) {
		return null;
	}
	if (!arg1 && arg1 !== 0) {
		return null;
	} else if (arg1 === 0) {
		return 0;
	}
	arg1 = toNonExponential(arg1);
	arg2 = toNonExponential(arg2);
	var n1, n2;
	var r1, r2; // 小数位数
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	n1 = Number(arg1.toString().replace(".", ""));
	n2 = Number(arg2.toString().replace(".", ""));
	return fnFloatMultiply((n1 / n2), Math.pow(10, r2 - r1));
	// return (n1 / n2) * Math.pow(10, r2 - r1);   // 直接乘法还是会出现精度问题
}

/**
 * fnFloatMod 取余 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 12.24 % 12  ==> 0.2400000000000002
 * {{ 12.24 | mod( -12 ) }}  ==>  0.24
 */
export function fnFloatMod(arg1, arg2) {
	arg1 = Number(arg1);
	arg2 = Number(arg2);
	if (!arg2) {
		return null;
	}
	if (!arg1 && arg1 !== 0) {
		return null;
	} else if (arg1 === 0) {
		return 0;
	}
	let intNum = arg1 / arg2;
	intNum = intNum < 0 ? Math.ceil(arg1 / arg2) : Math.floor(arg1 / arg2); // -1.02 取整为 -1; 1.02取整为1
	let intVal = fnFloatMultiply(intNum, arg2);
	return fnFloatSub(arg1, intVal);
}
