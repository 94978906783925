import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
/*
/*
 * path : 字符串 浏览器地址
 * component : function 懒加载
   路由级别的代码拆分
   为该路由生成一个单独的chunk (login.[hash].js)
 * meta: {
	 title : String 页面标题,
	 keepAlive : Boolean 是否缓存
	 resetType	: String 0不重置，1重置, 2不做任何动作
	}
 * */
/*
 * redirect : 路由跳转
 */
const aAllPlay = [{
	name: 'Football',
	path: '/football',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/football.vue'),
	meta: {
		title: '竞彩足球', // 页面标题
		hidden: true,
		keepAlive: false, // keep-alive 标识
	}
}, {
	name: 'Basketball',
	path: '/basketball',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/basketball.vue'),
	meta: {
		title: '竞彩篮球', // 页面标题
		hidden: true,
		keepAlive: false, // keep-alive 标识
	}
}, {
	name: 'Bet',
	path: '/bet',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/bet.vue'),
	meta: {
		title: '方案详情', // 页面标题
		hidden: true,
		keepAlive: false, // keep-alive 标识
	}
}, {
	name: 'BetLQ',
	path: '/betLQ',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/betLQ.vue'),
	meta: {
		title: '方案详情', // 页面标题
		hidden: true,
		keepAlive: false, // keep-alive 标识
	}
}, {
	name: 'Optimize',
	path: '/optimize',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/optimize.vue'),
	meta: {
		title: '奖金优化', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
}, {
	name: 'Release',
	path: '/release',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/release.vue'),
	meta: {
		title: '发布跟单', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
}]
const aHome = [{
	name: 'Home',
	path: '/',
	component: () => import( /* webpackChunkName: "home" */ '../views/home/index.vue'),
	meta: {
		title: '首页', // 页面标题
		hidden: false,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
},{
	name: 'Searchfor',
	path: '/searchfor',
	component: () => import( /* webpackChunkName: "match" */ '../views/home/searchfor.vue'),
	meta: {
		title: '搜索', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
},{
	name: 'Zhuye',
	path: '/zhuye',
	component: () => import( /* webpackChunkName: "match" */ '../views/home/zhuye.vue'),
	meta: {
		title: '搜索', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
},{
	name: 'Fensi',
	path: '/fensi',
	component: () => import( /* webpackChunkName: "match" */ '../views/home/fensi.vue'),
	meta: {
		title: '我的粉丝', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
},{
	name: 'Guanzhu',
	path: '/guanzhu',
	component: () => import( /* webpackChunkName: "match" */ '../views/home/guanzhu.vue'),
	meta: {
		title: '我的粉丝', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
},{
	name: 'GodBd',
	path: '/godBd',
	component: () => import( /* webpackChunkName: "match" */ '../views/home/godBd.vue'),
	meta: {
		title: '搜索', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
},{
	name: 'Match',
	path: '/match',
	component: () => import( /* webpackChunkName: "match" */ '../views/match/match.vue'),
	meta: {
		title: '赛事', // 页面标题
		hidden: false,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
},{
	name: 'DetailMatch',
	path: '/detailMatch',
	component: () => import( /* webpackChunkName: "match" */ '../views/match/detailMatch.vue'),
	meta: {
		title: '评论', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
}];
const aLogin = [{
		name: 'LoginPw',
		path: '/loginPw',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/loginPw.vue'),
		meta: {
			title: '密码登录', // 页面标题
			hidden: true,
			keepAlive: true, // keep-alive 标识
			resetType: "1",
		}
	},
	{
		name: 'LoginCode',
		path: '/loginCode',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/loginCode.vue'),
		meta: {
			title: '手机号登录', // 页面标题
			hidden: true,
			keepAlive: true,
			resetType: "1",
		}
	},
	{
		name: 'Register',
		path: '/register',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/register.vue'),
		meta: {
			title: '注册',
			hidden: true,
			keepAlive: true,
			resetType: "1",
		}
	},
	{
		name: 'ForgetPw',
		path: '/forgetPw',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/forgetPw.vue'),
		meta: {
			title: '忘记密码',
			hidden: true,
			keepAlive: false
		}
	}, {
		name: 'BindIdCard',
		path: '/bindIdCard',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/bindIdCard.vue'),
		meta: {
			title: '实名认证',
			hidden: true,
			keepAlive: false
		}
	}, {
		name: 'BindShop',
		path: '/bindShop',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/bindShop.vue'),
		meta: {
			title: '绑定彩票店',
			hidden: true,
			keepAlive: false
		}
	}, {
		name: 'SelectRole',
		path: '/selectRole',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/selectRole.vue'),
		meta: {
			title: '选择角色',
			hidden: true,
			keepAlive: false
		}
	}, {
		name: 'AddRole',
		path: '/addRole',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/addRole.vue'),
		meta: {
			title: '新增角色',
			hidden: true,
			keepAlive: false
		}
	},
];
const aOther = [{
	name: 'GeneralTxt',
	path: '/generalTxt',
	component: () => import( /* webpackChunkName: "other" */ '../views/other/generalTxt.vue'),
	meta: {
		title: '协议',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'Result',
	path: '/result',
	component: () => import( /* webpackChunkName: "other" */ '../views/other/result.vue'),
	meta: {
		title: '充值成功',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'Redirect',
	path: '/redirect',
	component: () => import( /* webpackChunkName: "other" */ '../views/other/redirect.vue'),
	meta: {
		title: '中转',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'Web',
	path: '/web',
	component: () => import( /* webpackChunkName: "other" */ '../views/other/web.vue'),
	meta: {
		title: '网页',
		hidden: true,
		keepAlive: false,
	}
}];
const aPlan = [{
	name: 'Plan',
	path: '/plan',
	component: () => import( /* webpackChunkName: "plan" */ '../views/plan/plan.vue'),
	meta: {
		title: '方案',
		hidden: false,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'PlanDetail',
	path: '/planDetail',
	component: () => import( /* webpackChunkName: "plan" */ '../views/plan/detail.vue'),
	meta: {
		title: '方案详情',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'SearchPlan',
	path: '/searchPlan',
	component: () => import( /* webpackChunkName: "plan" */ '../views/plan/searchPlan.vue'),
	meta: {
		title: '搜索',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'Comment',
	path: '/comment',
	component: () => import( /* webpackChunkName: "plan" */ '../views/plan/comment.vue'),
	meta: {
		title: '评论',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'FensiPlan',
	path: '/fensiPlan',
	component: () => import( /* webpackChunkName: "plan" */ '../views/plan/fensiPlan.vue'),
	meta: {
		title: '点赞列表',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}];
const aMine = [{
	name: 'Mine',
	path: '/mine',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/mine.vue'),
	meta: {
		title: '我的',
		hidden: false,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'MineInfo',
	path: '/mineInfo',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/mineInfo.vue'),
	meta: {
		title: '个人中心',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'Notices',
	path: '/notices',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/notices/notices.vue'),
	meta: {
		title: '消息',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'NoticeDetail',
	path: '/noticeDetail',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/notices/noticeDetail.vue'),
	meta: {
		title: '消息详情',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'RecordFD',
	path: '/recordFD',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/recordFD.vue'),
	meta: {
		title: '发单记录',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'RecordGD',
	path: '/recordGD',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/recordGD.vue'),
	meta: {
		title: '跟单记录',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'Xiaoxi',
	path: '/xiaoxi',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/xiaoxi.vue'),
	meta: {
		title: '消息',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'ListGDR',
	path: '/listGDR',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/listGDR.vue'),
	meta: {
		title: '跟单列表',
		hidden: true,
		keepAlive: false,
		resetType: "1",
	}
}, {
	name: 'Withdraw',
	path: '/withdraw',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/withdraw.vue'),
	meta: {
		title: '提现',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'Recharge',
	path: '/recharge',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/recharge.vue'),
	meta: {
		title: '充值',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'ListBANK',
	path: '/listBANK',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/listBANK.vue'),
	meta: {
		title: '银行名称',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'Setup',
	path: '/setup',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/setup.vue'),
	meta: {
		title: '设置',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'About',
	path: '/about',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/about.vue'),
	meta: {
		title: '关于',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'ModifyPw',
	path: '/modifyPw',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/modifyPw.vue'),
	meta: {
		title: '修改密码',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'SetupPw',
	path: '/setupPw',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/setupPw.vue'),
	meta: {
		title: '设置密码',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'CapitalAll',
	path: '/capitalAll',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/capital/capitalAll.vue'),
	meta: {
		title: '资金明细',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'CapitalStore',
	path: '/capitalStore',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/capital/capitalStore.vue'),
	meta: {
		title: '店内余额',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'CapitalFrozen',
	path: '/capitalFrozen',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/capital/capitalFrozen.vue'),
	meta: {
		title: '冻结资金',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'CapitalExtraction',
	path: '/capitalExtraction',
	component: () => import( /* webpackChunkName: "mine" */ '../views/mine/capital/capitalExtraction.vue'),
	meta: {
		title: '待提佣金',
		hidden: true,
		keepAlive: false,
	}
}];
const aRedPack = [{
	name: 'RedPack',
	path: '/redPack',
	component: () => import( /* webpackChunkName: "redPack" */ '../views/redPack/redPack.vue'),
	meta: {
		title: '红包记录',
		hidden: true,
		keepAlive: false,
	}
},{
	name: 'RedPackList',
	path: '/redPackList',
	component: () => import( /* webpackChunkName: "redPack" */ '../views/redPack/redPackList.vue'),
	meta: {
		title: '红包',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'SendHB',
	path: '/sendHB',
	component: () => import( /* webpackChunkName: "redPack" */ '../views/redPack/sendHB.vue'),
	meta: {
		title: '发红包',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'DetailHB',
	path: '/detailHB',
	component: () => import( /* webpackChunkName: "redPack" */ '../views/redPack/detailHB.vue'),
	meta: {
		title: '红包详情',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'RecordHB',
	path: '/recordHB',
	component: () => import( /* webpackChunkName: "redPack" */ '../views/redPack/recordHB.vue'),
	meta: {
		title: '红包记录',
		hidden: true,
		keepAlive: false,
	}
}];
const aTest = [{
	name: 'Test',
	path: '/test',
	component: () => import( /* webpackChunkName: "test" */ '../views/test/index.vue'),
	meta: {
		title: '测试页面',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'TestSwiper',
	path: '/testSwiper',
	component: () => import( /* webpackChunkName: "test" */ '../views/test/swiper/swiper.vue'),
	meta: {
		title: '轮播图',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'TestBasketball',
	path: '/testBasketball',
	component: () => import( /* webpackChunkName: "test" */ '../views/test/allPlay/basketball.vue'),
	meta: {
		title: '竞彩篮球',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'TestFootball',
	path: '/testFootball',
	component: () => import( /* webpackChunkName: "test" */ '../views/test/allPlay/football.vue'),
	meta: {
		title: '竞彩足球',
		hidden: true,
		keepAlive: false,
	}
}, {
	name: 'TestTemplateCache',
	path: '/testTemplateCache',
	component: () => import( /* webpackChunkName: "test" */ '../views/test/template/cache.vue'),
	meta: {
		title: '模版页面-缓存',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'TestJs',
	path: '/testJs',
	component: () => import( /* webpackChunkName: "test" */ '../views/test/js/js.vue'),
	meta: {
		title: 'JS方法测试页面',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}, {
	name: 'TestClipboard',
	path: '/testClipboard',
	component: () => import( /* webpackChunkName: "test" */ '../views/test/js/clipboard.vue'),
	meta: {
		title: '剪切板',
		hidden: true,
		keepAlive: true,
		resetType: "1",
	}
}];
// 仅在dev环境展示
const aDevShow = [{
	name: 'Beijingball',
	path: '/beijingball',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/beijingball.vue'),
	meta: {
		title: '北京单场', // 页面标题
		hidden: true,
		keepAlive: true, // keep-alive 标识
		resetType: "1",
	}
}, {
	name: 'BetBD',
	path: '/betBD',
	component: () => import( /* webpackChunkName: "allPlay" */ '../views/allPlay/betBD.vue'),
	meta: {
		title: '方案详情', // 页面标题
		hidden: true,
		keepAlive: false, // keep-alive 标识
	}
}]
const routes = [
	...aAllPlay,
	...aHome,
	...aLogin,
	...aOther,
	...aPlan,
	...aMine,
	...aRedPack,
	{
		path: '/home',
		redirect: {
			name: 'Home'
		}
	}, {
		path: '/index',
		redirect: {
			name: 'Home'
		}
	}, {
		path: '/:pathMatch(.*)',
		redirect: {
			name: 'Home'
		}
	}
]
console.log(process.env.NODE_ENV);
// 测试环境才能使用aTest
if (process.env.NODE_ENV === "development") {
	routes.unshift(...aTest,...aDevShow)
}
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// router.beforeEach((to, from, next) => {
// 	next()
// })
// router.afterEach((to, from) => {})
export default router
