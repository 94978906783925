import router from './router'
// import store from './store'
import Lockr from '@/utils/lockr.js'

// 免登录白名单
const whiteList = ['/','/plan','/searchfor','/godBd','/zhuye','/planDetail','/match','/detailMatch','/bet','/betLQ','/comment','/searchPlan','/loginPw', '/loginCode','/bindIdCard','/addRole','/selectRole', '/register', '/forgetPw', '/generalTxt','/redirect'];
// 最后一个地址
// let lastPath = null;
router.beforeEach((to, from, next) => {
	console.log(to, from);
	// console.log("token存在：", Lockr.get("userInfo/S_TOKEN"));
	// console.log(to, from);
	// console.log(Lockr.get("userInfo/S_TOKEN") , Lockr.get("userInfo/S_LOGININFO"))
	// 如果最后一个地址同等于准备前往的地址,则看是否需要重置
	// if (lastPath === to.path) {
	// 	const {
	// 		resetType
	// 	} = to.params;
	// 	to.meta.resetType = resetType ? resetType : '1';
	// }
	// 将最后一个地址保存
	// lastPath = from.path;
	// 查看token是否存在
	if (Lockr.get("userInfo/S_TOKEN") && Lockr.get("userInfo/S_LOGININFO")) {
		// console.log("已获取到token")
		// 没有token

		if (whiteList.indexOf(to.path) !== -1) {
			// 该用户已实名认证，并且不是从登陆页面进入的
			// 比如已经实名认证，选择角色时返回需要直接返回登陆页
			if (to.path === '/bindIdCard' && from.path !== '/loginPw' && Lockr.get("userInfo/S_USERINFO")['certNo']) {
				next('/loginPw');
			} else {
				// 在免登录白名单，直接进入
				next();
			}
		} else {
			// 查看用户信息是否存在
			if (Lockr.get("userInfo/S_USERINFO")) {
				// 查看该用户是否实名认证
				// if (Lockr.get("userInfo/S_USERINFO")['certNo']) {
					// 已实名
					// 查看该用户是否绑定彩票店
					// if (Lockr.get("userInfo/S_USERINFO")['shopId']) {
						// 绑定
						// 查看该用户是否有默认角色
						if (Lockr.get("userInfo/S_USERINFO")['roleName'] && Lockr.get("userInfo/S_USERINFO")[
								'roleId']) {
							// 有角色
							next();
						} else {
							// 没有角色
							// 如果前往的地址是selectRole，则直接前往
							if (to.path === '/selectRole') {
								next();
							} else {
								next('/selectRole');
							}
						}
					// } else {
						// 没有绑定
						// 如果前往的地址是bindShop，则直接前往
					// 	if (to.path === '/bindShop') {
					// 		next();
					// 	} else {
					// 		next('/bindShop');
					// 	}
					// }
				// }
				// else {
				// 	// 没用实名
				// 	// 如果前往的地址是bindIdCard，则直接前往
				// 	if (to.path === '/bindIdCard') {
				// 		next();
				// 	} else {
				// 		next('/bindIdCard');
				// 	}
				// }
			} else {
				// store.dispatch('GetUserInfo', Lockr.get("userInfo/S_LOGININFO")['accountNo']).then(() => {
				// 	// 查看该用户是否实名认证
				// 	if (Lockr.get("userInfo/S_USERINFO")['realName']) {
				//
				// 		// 已实名
				// 		// 查看该用户是否绑定彩票店
				// 		// if (Lockr.get("userInfo/S_USERINFO")['shopId']) {
				// 			// 绑定
				// 			// 查看该用户是否有默认角色
				// 			if (Lockr.get("userInfo/S_USERINFO")['roleName'] && Lockr.get(
				// 					"userInfo/S_USERINFO")['roleId']) {
				// 				// 有角色
				// 				next();
				// 			} else {
				// 				// 没有角色
				// 				// 如果前往的地址是selectRole，则直接前往
				// 				if (to.path === '/selectRole') {
				// 					next();
				// 				} else {
				// 					next('/selectRole');
				// 				}
				// 			}
				// 		// } else {
				// 		// 	// 没有绑定
				// 		// 	// 如果前往的地址是bindShop，则直接前往
				// 		// 	if (to.path === '/bindShop') {
				// 		// 		next();
				// 		// 	} else {
				// 		// 		next('/bindShop');
				// 		// 	}
				// 		// }
				// 	} else {
				// 		// 没用实名
				// 		// 如果前往的地址是bindIdCard，则直接前往
				// 		if (to.path === '/bindIdCard') {
				// 			next();
				// 		} else {
				// 			next('/bindIdCard');
				// 		}
				// 	}
				// }).catch(() => {
				// 	fFedLogOut(next, '/loginPw')
				// })
			}
		}
	} else {
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			// 用于注册，忘记密码页面
			next();
		} else {
			next('/loginPw'); // 否则全部重定向到登录页
		}
	}
})
// 退出登录
// function fFedLogOut(next, path) {
// 	store.dispatch('userInfo/FedLogOut');
// 	next(path);
// }
// 全局的后置钩子
// 该函数会在每次路由切换成功进入激活阶段时被调用。
router.afterEach(() => {})
