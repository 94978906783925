import * as types from '../mutation-types';
import Lockr from '@/utils/lockr.js';

import {
	apiLogin,
	apiUserInfo,
	apiLogout,
	apiRegister,
	apiSetPassword
} from '@/api/useInfo.js'
const userInfo = {
	namespaced: true, // 命名空间
	state: {
		token: Lockr.get(types.S_TOKEN) ? Lockr.get(types.S_TOKEN) : "",
		userInfo: Lockr.get(types.S_USERINFO) ? Lockr.get(types.S_USERINFO) : {},
		loginInfo: Lockr.get(types.S_LOGININFO) ? Lockr.get(types.S_LOGININFO) : {},
		myInfo: Lockr.get(types.S_MYINFO) ? Lockr.get(types.S_MYINFO) : {},
		// subAppId 彩店ID
		subAppId: Lockr.get(types.S_SUBAPPID) ? Lockr.get(types.S_SUBAPPID) : null,
	},

	mutations: {
		[types.S_TOKEN]: (state, token) => {
			state.token = token;
		},
		[types.S_USERINFO]: (state, userInfo) => {
			state.userInfo = userInfo
		},
		[types.S_LOGININFO]: (state, loginInfo) => {
			state.loginInfo = loginInfo
		},
		[types.S_MYINFO]: (state, obj) => {
			state.myInfo = obj
		},
		// subAppId 彩店ID
		[types.S_SUBAPPID]: (state, res) => {
			state.subAppId = res;
		}
	},

	actions: {
		/**
		 * [USubAppid  更新彩店ID]
		 * @param  {[Object]} {commit} [  ]
		 * @return
		 */
		USubAppid({
			commit,
			state
		}, Str) {
			if (!state.subAppId || !state.userInfo.token) {
				// 如果不存在subAppId，则存入
				// 如果未登录情况，则存入
				Lockr.set(types.S_SUBAPPID, Str);
				commit(types.S_SUBAPPID, Str);
			}
		},
		/**
		 * [Register  注册]
		 * @param  {[Object]} {commit,state} [  ]
		 * @param  {[Object]} userInfo [ 注册信息 ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		Register({
			dispatch
		}, userInfo) {
			console.log(userInfo);
			return new Promise((resolve, reject) => {
				apiRegister(userInfo).then(() => {
					console.log("开始登录+++++++++");
					// 获取用户信息
					return dispatch('Login', {
						accountNo: userInfo.phone,
						certificate: userInfo.password,
					})
				}).then(res => {
					console.log("登录结束+++++++++", res);
					resolve({
						result: {
							path: "/"
						}
					});
				}).catch(error => {
					console.log("失败----------", error)
					reject(error)
				})
			})
		},
		/**
		 * [Register  忘记密码]
		 * @param  {[Object]} obj [ 数据对象 ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		ForgetPw({
			commit
		}, obj) {
			console.log(obj)
			return new Promise((resolve, reject) => {
				apiRegister(obj).then(() => {
					console.log(commit);
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		/**
		 * [Login  登录]
		 * @param  {[Object]} {dispatch,commit} [  ]
		 * @param  {[Object]} userInfo [ 注册信息 ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		Login({
			// dispatch,
			commit
		}, userInfo) {
			console.log("登录信息为：", userInfo);
			// if()
			// userInfo.accountNo = userInfo.phone
			// userInfo.certificate = userInfo.password
			return new Promise((resolve, reject) => {
				apiLogin({
					phone: userInfo.accountNo,
					password: userInfo.certificate,
					// loginType:  userInfo.loginType
				}).then(res => {
					console.log("登录成功+++++++++", res)
					// 登录会返回3个参数（token临时标识，userId用户唯一标识，userType用户类型);
					let data = res.result;
					Lockr.set(types.S_TOKEN, data.token);
					commit(types.S_TOKEN, data.token);
					Lockr.set(types.S_LOGININFO, {
						...data,
						phone: userInfo.accountNo
					});
					commit(types.S_LOGININFO, {
						...data,
						phone: userInfo.accountNo
					});
					// 开始获取用户信息
					// return dispatch('GetUserInfo', data.userId);
					console.log(data);
					Lockr.set(types.S_USERINFO, data);
					commit(types.S_USERINFO, data);
					// console.log(Lockr.get("userInfo/S_USERINFO"));
					// 同步用户登录信息
					// let tData = {
					// 	...state.loginInfo,
					// 	...data
					// };
					// Lockr.set(types.S_LOGININFO, tData);
					// commit(types.S_LOGININFO, tData);
					resolve();
				}).then((res) => {
					console.log("登录接口已接受到用户信息成功状态+++++++++++", res)
					// 接受到上一个then return的resolve
					resolve("登录接口已接受到用户信息成功状态");
				}).catch((err) => {
					console.log(err);
					// 登录失败
					reject({
						path: "/loginPw"
					})
				})
			})
		},
		/**
		 * [UUserInfo 更新用户信息]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[Object]} Obj [ 用户信息 ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		UUserInfo({
			commit
		}, Obj) {
			Lockr.set(types.S_USERINFO, Obj);
			commit(types.S_USERINFO, Obj);
		},
		/**
		 * [GetUserInfo  获取用户信息]
		 * @param  {[Object]} {dispatch, commit, state} [  ]
		 * @param  {[String]} userId [ 用户id ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		GetUserInfo({
			dispatch,
			commit,
			state
		}, id) {
			return new Promise((resolve, reject) => {
				apiUserInfo({
					id
				}).then(res => {
					dispatch('allPlay/CleanAllPlayData', {}, { root: true });
					dispatch('other/CleanOtherData', {}, { root: true });

					let data = res && res.result ? res.result : {};
					console.log("存储用户信息-----------", data);
					Lockr.set(types.S_USERINFO, data);
					commit(types.S_USERINFO, data);
					// 同步用户登录信息
					let tData = {
						...state.loginInfo,
						...data
					};

					Lockr.set(types.S_LOGININFO, tData);
					commit(types.S_LOGININFO, tData);
					resolve();
				}).catch(error => {
					reject(error)
				})
			})
		},
		/**
		 * [SetupPw 修改密码]
		 * @param {Object} {commit,state}
		 * @param {Object} {pData}
		 * @return {Promise} [后台返回结果]
		 */
		SetupPw({
			// dispatch,
			state
		}, pData) {
			return new Promise((resolve, reject) => {
				apiSetPassword({
					userId: state.loginInfo.userId,
					...pData
				}).then(() => {
					// return dispatch("GetUserInfo", state.loginInfo.userId)
				}).then(() => {
					return resolve();
				}).catch((e) => {
					return reject(e)
				});
			})
		},
		/**
		 * [LogOut  退出系统]
		 * @param  {[Object]} {commit,state} [  ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		LogOut({
			dispatch,
			state
		}) {
			return new Promise((resolve) => {
				apiLogout({
					"userId": state.loginInfo.userId,
					"appTerm": "H5",
					"token": state.token,
					"userType": "C"
				}).then(() => {
					dispatch('FedLogOut');
					return resolve();
				}).catch(() => {
					dispatch('FedLogOut');
					return resolve()
				});
			})
		},
		/**
		 * [LogOut  前端登出]
		 * @param  {[Object]} {commit} [  ]
		 * @return {Promise}      [ 后台返回结果 ]
		 */
		FedLogOut({
			commit
		}) {
			commit(types.S_TOKEN, '');
			commit(types.S_USERINFO, {});
			commit(types.S_LOGININFO, {});
			commit(types.S_MYINFO, {});
			Lockr.rm(types.S_TOKEN);
			Lockr.rm(types.S_USERINFO);
			Lockr.rm(types.S_LOGININFO);
			Lockr.rm(types.S_MYINFO);
			// dispatch('CleanOtherData');
			// dispatch('CleanAllPlayData');
		},
		/**
		 * [UMyInfo 更新我的信息]
		 * @param  {[Object]} {commit} [  ]
		 * @param  {[Object]} [ 消息详情对象 ]
		 * @return
		 */
		UMyInfo({
			commit
		},res) {
			Lockr.set(types.S_MYINFO, res);
			commit(types.S_MYINFO,res);
		}
	}
}
export default userInfo
